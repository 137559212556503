
#app { font-family: 'Avenir', Helvetica, Arial, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; text-align: center; color: #2c3e50;
}
.checkoutArrowDown { max-width:200px; margin: 0 auto; display:block;
}
.checkoutFormWrapper { margin: 10px 0;
}
.checkoutFormWrapper h3 { text-align:center;
}
.checkoutFormWrapper h3 small { color: #777; font-size:15px;
}
.checkoutFormRow { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; -webkit-box-align: center; -ms-flex-align: center; align-items: center; margin: 10px 10px;
}
.checkoutFormContainer { max-width: 800px; margin: 0 auto; display: block;
}
.checkoutFormContainer input, .checkoutFormContainer select { width: 100%;
}
.checkoutFormContainer input.postalCode { width:30%;
}
.checkoutFormContainer input.townCity { width:70%;
}
.checkoutFormContainer .btn { margin: 0 auto; background-color: #22A340; color: #FFFFFF; padding: 15px 29px; border-radius: 3px;
}
.v--modal-top-right { right: 0px !important;
}
.greenButton {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  width: auto;
  max-width: 250px;
  margin: 30px auto;
  position: relative;
  padding: 7.5px;
  text-align: center;
}
.greenButton:before,
.greenButton:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 100%;
  border: 2px solid red;
  transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  -webkit-transform: rotate(3deg);
  -webkit-box-shadow: inset red -1px 0 0, inset red -2px 0 0, inset red -3px 0 0, inset red -4px 0 0;
          box-shadow: inset red -1px 0 0, inset red -2px 0 0, inset red -3px 0 0, inset red -4px 0 0;
  z-index: 50;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}
.greenButton:hover:before {
  transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  -webkit-transform: rotate(10deg);
}
.greenButton:after {
  transform: rotate(-170deg);
  -ms-transform: rotate(-170deg);
  -webkit-transform: rotate(-170deg);
}
.greenButton:hover:after {
  transform: rotate(-175deg);
  -ms-transform: rotate(-175deg);
  -webkit-transform: rotate(-175deg);
}
.greenButton a,
.greenButton label,
.greenButton button,
.greenButton input[type=button],
.greenButton input[type=submit] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  z-index: 1000;
  display: block;
  width: 100%;
  min-width: 200px;
  margin: 0 auto;
  padding: .3em .4em .4em .4em;
  font-size: 24pt;
  font-weight: 600;
  line-height: 1;
  -webkit-font-kerning: auto;
          font-kerning: auto;
  text-decoration: none;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  cursor: pointer;
  background: #6a1;
  background: -webkit-gradient(linear, left top, left bottom, from(#6a1),to(#3d6c04));
  background: linear-gradient(to bottom, #6a1 0%,#3d6c04 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6a1', endColorstr='#3d6c04',GradientType=0 );
  color: #fff;
  text-shadow: #062 1px 1px 0;
  border: 1px solid #6a1;
  border-bottom-color: #005822;
  border-radius: 3px;
  -webkit-box-shadow: inset #062 0 -5px 15px, inset #8e8 0 1px 1px, white 0 1px 0, white 1px 0 0, white -1px 0 0, white 0 -1px 0, white 0 2px 0, white 2px 0 0, white -2px 0 0, white 0 -2px 0, white 2px 2px 0, white 2px -2px 0, white -2px -2px 0, white -2px 2px 0, rgba(0,0,0,.15) 0px 3px 5px;
          box-shadow: inset #062 0 -5px 15px, inset #8e8 0 1px 1px, white 0 1px 0, white 1px 0 0, white -1px 0 0, white 0 -1px 0, white 0 2px 0, white 2px 0 0, white -2px 0 0, white 0 -2px 0, white 2px 2px 0, white 2px -2px 0, white -2px -2px 0, white -2px 2px 0, rgba(0,0,0,.15) 0px 3px 5px;
}
.greenButton a:hover,
.greenButton a:focus,
.greenButton label:hover,
.greenButton label:focus,
.greenButton button:hover,
.greenButton button:focus,
.greenButton input[type=button]:hover,
.greenButton input[type=button]:focus,
.greenButton input[type=submit]:hover,
.greenButton input[type=submit]:focus {
  background: #6d4;
  color: white;
  background: -webkit-gradient(linear, left top, left bottom, from(#6d4),to(#3d6c04));
  background: linear-gradient(to bottom, #6d4 0%,#3d6c04 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7a3', endColorstr='#3d6c04',GradientType=0 );
  -webkit-box-shadow: inset #064 0 -5px 15px, inset #8e8 0 1px 1px, rgba(0, 255, 75, 0.5) 0 0 10px, white 0 1px 0, white 1px 0 0, white -1px 0 0, white 0 -1px 0, white 0 2px 0, white 2px 0 0, white -2px 0 0, white 0 -2px 0, white 2px 2px 0, white 2px -2px 0, white -2px -2px 0, white -2px 2px 0, rgba(0,0,0,.15) 0px 1px 1px;
          box-shadow: inset #064 0 -5px 15px, inset #8e8 0 1px 1px, rgba(0, 255, 75, 0.5) 0 0 10px, white 0 1px 0, white 1px 0 0, white -1px 0 0, white 0 -1px 0, white 0 2px 0, white 2px 0 0, white -2px 0 0, white 0 -2px 0, white 2px 2px 0, white 2px -2px 0, white -2px -2px 0, white -2px 2px 0, rgba(0,0,0,.15) 0px 1px 1px;
}
.greenButton a:active,
.greenButton label:active,
.greenButton button:active,
.greenButton input[type=button]:active,
.greenButton input[type=submit]:active {
  background: #3d6c04;
  border: 1px solid #005822;
  border-bottom-color: #052;
  -webkit-box-shadow: inset #040 0 -5px 15px, inset #8e8 0 1px 1px, white 0 1px 0, white 1px 0 0, white -1px 0 0, white 0 -1px 0, white 0 2px 0, white 2px 0 0, white -2px 0 0, white 0 -2px 0, white 2px 2px 0, white 2px -2px 0, white -2px -2px 0, white -2px 2px 0;
          box-shadow: inset #040 0 -5px 15px, inset #8e8 0 1px 1px, white 0 1px 0, white 1px 0 0, white -1px 0 0, white 0 -1px 0, white 0 2px 0, white 2px 0 0, white -2px 0 0, white 0 -2px 0, white 2px 2px 0, white 2px -2px 0, white -2px -2px 0, white -2px 2px 0;
}

